<template>
  <div class="product-detail-view">
    <div class="safty-wrapper">
      <div class="wrap-paper">
        <div class="sub-title-row">
          <div class="sub-title-cn">产品信息</div>
          <div class="sub-title-en">Product information</div>
        </div>

        <div class="product-info-row">
          <div class="product-image">
            <img :src="detail.poster" alt="" />
          </div>
          <div class="product-content">
            <div class="product-content-item">
              <div class="product-content-label">产品名称</div>
              <div class="product-content-value">{{ detail.name }}</div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">价格</div>
              <div class="product-content-value">
                {{ detail.price | currency }} 万元/{{ detail.unit }}
              </div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">年销售额</div>
              <div class="product-content-value">
                {{ detail.salesVolume | currency }} 万元
              </div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">供货地区</div>
              <div class="product-content-value">{{ detail.salesArea }}</div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">产品介绍</div>
              <div class="product-content-value">
                {{ detail.intro }}
              </div>
            </div>
          </div>
        </div>

        <div class="sub-title-row" style="margin-top: 64px">
          <div class="sub-title-cn">公司信息</div>
          <div class="sub-title-en">Company information</div>
        </div>

        <div class="company-baseinfo-row">
          <div class="company-picture">
            <img :src="detail.logo" alt="" />
          </div>
          <div class="company-base-content">
            <div class="company-code">公司代码：{{ detail.code }}</div>
            <div class="company-title">{{ detail.memberName }}</div>
            <div class="company-type">
              {{ { 0: "集成类", 1: "供应商", 2: "招标方" }[detail.type] }}
            </div>
          </div>
          <div class="company-detail-link-row">
            <a href="javascript:void(0)">了解详情</a>
          </div>
        </div>

        <div class="company-business-row">
          <p>
            {{ detail.memo }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.params.id;
      this.$axios
        .get("/product/productDetails/" + id, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.detail = res.data;
          console.log(res.data);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.product-detail-view {
  background: #f9f9f9;
  .safty-wrapper {
    width: $safety-width;
    margin: 0 auto;
    padding: 125px 0 114px 0;
    .wrap-paper {
      background: #ffffff;
      padding: 56px 169px 64px 150px;
      .sub-title-row {
        display: flex;
        align-items: flex-end;

        padding-bottom: 32px;
        .sub-title-cn {
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: 10px;
            width: 32px;
            height: 2px;
            background: #3883ff;
          }
        }
        .sub-title-en {
          color: #999999;
          font-size: 18px;
          margin-left: 17px;
        }
      }
    }

    .product-info-row {
      display: flex;
      .product-image {
        width: 225px;
        height: 225px;
        background: #ddd;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .product-content {
        flex: 1;
        margin-left: 48px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .product-content-item {
          display: flex;
          // align-items: center;
          font-size: 18px;
          margin-bottom: 10px;
          .product-content-label {
            width: 72px;
            margin-right: 24px;
          }
          .product-content-value {
            flex: 1;
            p {
              margin: 0;
            }
          }
        }
      }
    }

    .company-baseinfo-row {
      display: flex;
      .company-picture {
        width: 123px;
        height: 123px;
        background: #ddd;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .company-base-content {
        margin-left: 32px;
        flex: 1;
        color: #333333;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .company-code {
          font-size: 20px;
        }
        .company-title {
          font-size: 28px;
        }
        .company-type {
          color: #263238;
          font-size: 20px;
        }
      }
      .company-detail-link-row {
        display: flex;
        align-items: flex-end;

        a {
          color: #3883ff;
          font-size: 18px;
          text-decoration: none;
        }
      }
    }

    .company-business-row {
      margin-top: 30px;
      border: 1px solid #ededed;
      padding: 16px;

      color: #333333;
      line-height: 25px;
      font-size: 18px;
      p {
        text-indent: 2em;
      }
    }
  }
}
</style>